@import "../bootstrap-extended/include"; // Bootstrap includes
@import "../components/include"; // Components includes

body {
  font-family: "Montserrat", "Roboto", Helvetica, Arial, serif !important;
}

.ant-input-affix-wrapper {
  &.form-control {
    display: flex;
  }
}

.ant-table {
  font-weight: 500;
  color: #303030;

  .ant-table-thead tr {
    th {
      font-weight: 600;
    }
  }
}

.ant-btn {
  border-radius: 5px;

  &.ant-btn-primary {
    background-color: $primary;
    border: none;
    border-radius: 0.358rem;

    &:focus,
    &:hover {
      box-shadow: 0 8px 25px -8px $primary;
      background-color: rgba($primary, 0.8);
      border: none;
    }
  }

  &.btn-primary {
    background-color: $primary;
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    border: none;
    border-radius: 0.358rem;

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: rgba($primary, 0.8);
    }
  }

  &.btn-green {
    background-color: $green;
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    border: none;
    border-radius: 0.358rem;

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: rgba($green, 0.8);
    }
  }

  &.ant-btn-outline {
    border: 1px solid $primary;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: $primary;
    border-radius: 0.358rem;

    &:focus,
    &:hover {
      color: $primary;
      border: 1px solid $primary;
      box-shadow: 0 8px 25px -8px #ccc;
    }
  }

  &.ant-btn-outline.btn-outline-green {
    border: 1px solid $green;
    background-color: transparent;
    color: $green;
    border-radius: 0.358rem;

    &:hover,
    &:focus {
      border-color: rgba($green, 0.8);
    }
  }

  &.btn-cancel {
    border: none;
    color: #303030;
    border-radius: 3px;
    background-color: #e0e0e0;
    font-weight: 700;

    &:hover,
    &:focus {
      color: #303030;
      background-color: #bdbdbd;
    }
  }
}

// textarea.ant-input {
//   height: 150px !important;
// }

.ant-form-vertical .ant-form-item-label > label {
  width: 100%;
}

.ant-form-item-label > label {
  font-weight: 600;
}

.wrapper {
  .ant-collapse-content > .ant-collapse-content-box {
    background-color: #ffffff;
  }

  .ant-select-multiple .ant-select-selection-item {
    color: #ffffff;
    background-color: rgba($primary, 0.8);
    border: none;

    .ant-select-selection-item-remove {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #ffff;
      }
    }
  }

  .ant-input-number {
    padding: 0.23rem 1rem;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.375rem;
  }

  .ant-input-affix-wrapper,
  .ant-input {
    padding: 0.438rem 1rem;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.375rem;
  }

  .ant-picker {
    border: 1px solid #d8d6de;
    border-radius: 0.375rem;
    padding: 0.438rem 1rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 36.25px;
    border-radius: 0.375rem;
  }

  .ant-table-row-expand-icon {
    background: transparent;
  }
}

//Dark
.dark-layout {
  .wrapper {
    .ant-collapse {
      background-color: $theme-dark-card-bg;

      .ant-collapse-item {
        .ant-collapse-header {
          color: $theme-dark-body-color;
        }
      }
    }

    .ant-collapse-content > .ant-collapse-content-box {
      background-color: $theme-dark-card-bg;
    }

    .ant-select-arrow {
      color: $theme-dark-body-color;
    }

    .ant-checkbox-inner {
      background-color: $theme-dark-card-bg;
    }

    .ant-picker {
      color: $theme-dark-body-color;
      background-color: $theme-dark-card-bg;
      background-clip: padding-box;
      border: 1px solid $theme-dark-headings-color;

      &:hover {
        background-color: $theme-dark-card-bg;
      }

      .anticon svg {
        fill: $theme-dark-body-color;
      }
    }

    .ant-input-number {
      color: $theme-dark-body-color;
      background-color: $theme-dark-card-bg;
      background-clip: padding-box;
      border: 1px solid $theme-dark-headings-color;
    }

    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: $theme-dark-card-bg;
      color: $theme-dark-body-color;
    }

    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
      color: $theme-dark-body-color;
      background-color: $theme-dark-card-bg;
      border-color: #ff4d4f;
    }

    .ant-form-item-has-error {
      &.ant-picker,
      &.ant-input-number .ant-form-item-has-error .ant-picker {
        color: $theme-dark-body-color;
        background-color: $theme-dark-card-bg;
        border-color: #ff4d4f;
      }
    }

    .ant-input {
      color: $theme-dark-body-color;
      background-color: $theme-dark-card-bg;
      background-clip: padding-box;
      border: 1px solid $theme-dark-headings-color;
    }

    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      color: $theme-dark-body-color;
      background-color: $theme-dark-card-bg;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $theme-dark-card-bg;
      color: $theme-dark-body-color;
      border: 1px solid $theme-dark-headings-color;
    }

    .ant-form-item-label > label {
      color: $theme-dark-body-color;
    }

    .ant-form-item-has-error .ant-picker:not([disabled]):hover {
      background-color: $theme-dark-card-bg;
      border-color: #ff4d4f;
    }

    .ant-table-wrapper {
      .ant-table {
        color: $theme-dark-body-color;
        background-color: $theme-dark-card-bg;

        .ant-table-container {
          background-color: $theme-dark-card-bg;

          .ant-table-header {
            &.ant-table-sticky-holder {
              background: $theme-dark-table-header-bg;
            }

            .ant-table-thead tr {
              th {
                background: $theme-dark-table-header-bg;
                color: $theme-dark-headings-color;
              }
            }
          }

          .ant-table-body {
            &::-webkit-scrollbar {
              width: 8px;
              height: 8px;
            }

            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 4px rgba($primary, 0.3);
            }

            &::-webkit-scrollbar-thumb {
              background: $primary !important;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: rgba($primary, 0.8);
            }

            .ant-table-tbody tr {
              &.ant-table-row-selected > td {
                background: $theme-dark-body-bg;
                border-color: $theme-dark-headings-color;
              }

              &:hover,
              &:focus {
                td {
                  background: $theme-dark-body-bg;
                }
              }

              td {
                border-color: $theme-dark-headings-color;
              }
            }
          }

          .ant-table-cell-fix-left {
            background-color: $theme-dark-card-bg;
          }
        }
      }
    }
  }
}

.table-menu-action {
  min-width: 15rem !important;
}

.ant-message {
  z-index: 9999 !important;
}

// .ant-select-dropdown {
//   z-index: 10;
// }

// ================================================================================================
//   File Name: aggrid.scss
//   Description: SCC file for Aggrid.
//   ----------------------------------------------------------------------------------------------
//   Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import '../../bootstrap-extended/include'; // Bootstrap includes
@import '../../components/include'; // Components includes

.aggrid {
  height: 600px;
  font-size: 1rem;
  color: $body-color;

  // Checkbox Color
  .ag-icon-checkbox-checked,
  .ag-icon-checkbox-indeterminate {
    color: $primary !important;
  }

  /*** TABLE BODY HEIGHT - FOOTER HEIGHT ***/
  .ag-layout-normal {
    border: solid 0px !important;
  }

  .ag-root-wrapper-body {
    min-height: calc(100% - 56px);
  }

  /*** HEADER TEXT  ***/
  .ag-header{
    background-color: $table-head-bg;
    border-top: 1px solid;
    border-color: $border-color;
    min-height: 0;
    height: 38px;
    border-bottom-color: $border-color !important;
  }
  .ag-header-cell-text {
    font-size: 1rem;
    font-family: $font-family-sans-serif;
    color: $body-color;
    font-weight: bold;
    font-style: 0.857rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:hover {
      color: $body-color;
    }
  }


  /*** TABLE SCROLLBAR ***/
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-300;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background: $body-bg;
    border-radius: 20px;
  }
}

/*** PAGE FILTER DROPDOWN ***/
.filter-btn {
  padding: 1.2rem !important;
  border-radius: 5rem;
  font-size: 0.95rem;

  &:after {
    background-image: url(str-replace(str-replace($chevron-down, 'currentColor', $body-color), '#', '%23'));
    left: 5px;
  }
}

/*** SORTING DROPDOWN WIDTH ***/
.sort-dropdown {
  .dropdown-menu {
    min-width: 4rem;
  }
}

// Component Input
// ========================================================================

.form-control[readonly] {
  opacity: 0.5;
}

select.form-control[multiple] {
  background-image: none !important;
}

.input-group {
  .form-control.is-invalid ~ .input-group-append .input-group-text {
    border-color: $danger;
  }
  &.is-invalid {
    .form-control,
    .input-group-append .input-group-text,
    .input-group-prepend .input-group-text {
      border-color: $danger !important;
    }
  }
}
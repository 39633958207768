/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.form-label {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #5E5873;
	font-weight: bold;
}

.ag-body-horizontal-scroll-viewport,
.ag-body-viewport.ag-layout-normal {
	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(#001529, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(#001529, 0.6);
	}

	&::-webkit-scrollbar-thumb:hover {
		background: rgba(#001529, 0.8);
	}

}


.ql-html-overlayContainer {
	.ql-html-popupContainer {
		.ql-html-textContainer {
			.ql-html-textArea {
				position: static;
				margin-top: 20px;
			}

			.ql-html-buttonGroup {
				position: static;
				transform: none;
				left: auto;
				display: flex;
				flex-direction: row;
				padding: 20px;
				justify-content: flex-end;

				.ql-html-buttonCancel {
					color: #fff;
					background-color: #82868b;
					border-color: #82868b;
					margin-right: 20px;
					font-size: 0.875rem;
					line-height: 1;
					transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
					text-align: center;
					border: 1px solid transparent;
					padding: 0.786rem 1.5rem;
					border-radius: 0.625rem;

					&:hover {
						color: #fff;
						background-color: #6f7378;
						border-color: #696d71
					}

					&:focus,
					&.focus {
						color: #fff;
						background-color: #6f7378;
						border-color: #696d71;
						box-shadow: 0 0 0 0.2rem rgba(149, 152, 156, 0.5)
					}

					&.disabled,
					&:disabled {
						color: #fff;
						background-color: #82868b;
						border-color: #82868b
					}

					&:not(:disabled):not(.disabled):active:focus,
					&:not(:disabled):not(.disabled).active,
					&.show>.ql-html-buttonCancel.dropdown-toggle {
						color: #fff;
						background-color: #696d71;
						border-color: #63666a
					}
				}


				.ql-html-buttonCancel:not(:disabled):not(.disabled):active:focus,
				.ql-html-buttonCancel:not(:disabled):not(.disabled).active:focus,
				.show>.ql-html-buttonCancel.dropdown-toggle:focus {
					box-shadow: 0 0 0 0.2rem rgba(149, 152, 156, 0.5)
				}

				.ql-html-buttonOk {
					color: #fff;
					background-color: #FB960B;
					border-color: #FB960B;
					display: inline-block;
					font-weight: 400;
					vertical-align: middle;
					font-size: 0.875rem;
					line-height: 1;
					transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
					text-align: center;
					border: 1px solid transparent;
					padding: 0.786rem 1.5rem;
					border-radius: 0.625rem;
				}

				.ql-html-buttonOk :hover {
					color: #fff;
					background-color: #dc8104;
					border-color: #d07a03
				}

				.ql-html-buttonOk :focus,
				.ql-html-buttonOk .focus {
					color: #fff;
					background-color: #dc8104;
					border-color: #d07a03;
					box-shadow: 0 0 0 0.2rem rgba(220, 134, 17, 0.5)
				}

				.ql-html-buttonOk .disabled,
				.ql-html-buttonOk :disabled {
					color: #2a2e30;
					background-color: #FB960B;
					border-color: #FB960B
				}

				.ql-html-buttonOk :not(:disabled):not(.disabled):active,
				.ql-html-buttonOk :not(:disabled):not(.disabled).active,
				.show>.ql-html-buttonOk .dropdown-toggle {
					color: #fff;
					background-color: #d07a03;
					border-color: #c37203
				}

				.ql-html-buttonOk :not(:disabled):not(.disabled):active:focus,
				.ql-html-buttonOk :not(:disabled):not(.disabled).active:focus,
				.show>.ql-html-buttonOk .dropdown-toggle:focus {
					box-shadow: 0 0 0 0.2rem rgba(220, 134, 17, 0.5)
				}


			}
		}
	}
}

.ag-row .ag-cell {
	display: flex;
	align-items: center;
}

.ellipsis-content {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.ellipsis-content-2 {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.ellipsis-content-3 {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.shortly-content {
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.d-flex {
	display: flex;
}

.align-center {
	align-items: center;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.mr-5 {
	margin-right: 5px;
}

.ml-5 {
	margin-left: 5px;
}

body {
	.wrs_editor .wrs_tickContainer {
		display: none;
	}
}

.ReactModalPortal .ReactModal__Overlay {
	z-index: 9999 !important;
}

.content-header-page {
	position: fixed;
	width: 100%;
	z-index: 100;
}

.content-page {
	padding-top: 50px;
}

.button-group {
    display: flex;

    .button-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        gap: 10px;

   
        height: 41px;

        border-radius: 8px;

        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 21px;
        color: #FD8A28;
        cursor: pointer;
        background: white;
        &.active{
            background: #FD8A28;
            color: whitesmoke;
        }
    }

}